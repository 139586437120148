<template>
    <div class="zongjie">
        <div class="zongjie-top">
            <p class="p1">高数(上) 基础学练：巩固提分</p>
            <div class="dis_flex dis_sb pt_16">
                <div class="item">
                    <p class="p2">
                        {{(bookInfo['学习时长']/60).toFixed(0) || 0}}
                        <span>min</span>
                    </p>
                    <p class="p3">学习时长</p>
                </div>
                <div class="item">
                    <p class="p2">
                        {{bookInfo['总做题数']}}
                    </p>
                    <p class="p3">做题数</p>
                </div>
                <div class="item">
                    <p class="p2">
                        {{bookInfo['准确率']}}
                        <span>%</span>
                    </p>
                    <p class="p3">正确率</p>
                </div>
            </div>
        </div>
        
        <div class="zongjie-con">
            <div class="title">
                总结
            </div>
            <div class="zongjie-info">
                <van-collapse v-model="activeNames">
                    <van-collapse-item :title="'第'+number[n]+'章：'+key+''" :name="n" v-for="(item,key,n) in zonjie_agg" :key="n">
                        <div class="pt_24 pb_24">
                            <div class="list" v-for="(list,key_1,n_1) in item" :key="n_1">
                                <div class="top">{{number[n_1]}}、{{key_1}}</div>
                                <div class="detail">
                                    <div class="item" v-for="(list_1,n_2) in list" :key="n_2">
                                        <div class="tigan">
                                            <template v-for="(list_2,n_3) in list_1['题干']">
                                                <span :key="n_3" v-if="list_2[0]=='str'" class="list_2">{{list_2[1]}}</span>
                                                <span :key="n_3" v-else-if="list_2[0]=='tex'" class="tex_ti" :id="'ti-'+n+'-'+n_1+'-'+n_2+'-'+n_3"></span>
                                            </template>
                                        </div>
                                        
                                        <div class="tishizongjie">
                                            <ul>
                                                <li v-for="(list_3,n_4) in list_1['提示总结']" :key="n_4">
                                                    <p class="p5" :id="'tishi-'+n+'-'+n_1+'-'+n_2+'-'+n_4">
                                                        <!-- {{list_3['提示']}} -->
                                                    </p>
                                                    <p class="p6">{{list_3['总结']}}</p>
                                                </li>
                                            </ul>
                                            <!-- {{list_1['提示总结']}} -->
                                        </div>
                                    </div>
                                    <!-- {{list}} -->
                                </div>
                                
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>

    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        name: 'zongjie',
        data() {
            return {
                activeNames: ['0'],
                zonjie_agg: {},
                number: ["一","二","三","四","五","六","七","八","九"],
                bookInfo: {}
            }
        },
        watch: {
            activeNames(newValue, oldValue) {
                this.xuanran()
            }
        },
        methods: {
            zongjie_query_agg() {
                let query = this.$route.query;
                let param = {
                    book: query.name,
                }
                this.$api.zongjie_query_agg({param,method:'get'},res=>{
                    this.zonjie_agg = res;
                });

            },
            xuanran(){
                let zonjie_agg = this.zonjie_agg;
                let item = Object.values(zonjie_agg);
                item.map((e,n)=>{
                    let list = Object.values(e);
                    list.map((el,n_1)=>{
                        el.map((el_1,n_2)=>{
                            el_1['题干'].map((el_2,n_3)=>{
                                if(el_2[0] == 'tex'){
                                    let id = 'ti-'+n+'-'+n_1+'-'+n_2+'-'+n_3;
                                    console.log('id', id);
                                    this.$nextTick().then(e=>{
                                        let dom = document.getElementById(id);
                                        console.log('dom', dom);
                                        this.$kx.render(el_2[1], dom, {
                                            displayMode: true,
                                        });

                                        let mcloseArr = $('.zongjie-info').find('.cjk_fallback');
                                        $.each(mcloseArr, function (index) {
                                            let item = mcloseArr[index];
                                            if ($(item).html() == "冇") {
                                                $(item).html("<input type='text' disabled class='inputClass no'>")
                                            }
                                        });
                                    });

                                }
                            })
                            el_1['提示总结'].map((el_4,n_4)=>{
                                let id_tishi = 'tishi-'+n+'-'+n_1+'-'+n_2+'-'+n_4;
                                this.$nextTick().then(e=>{
                                    let dom_tishi = document.getElementById(id_tishi);
                                    console.log('dom_tishi', dom_tishi);
                                    this.$kx.render(el_4['提示'], dom_tishi, {
                                        displayMode: true,
                                    });
                                })
                            })
                        })
                    })
                });
            },
            schedule_query(){
                let param ={
                    book: this.$route.query.name
                };
                this.$api.schedule_query({param,method:'post'},res=>{
                    this.bookInfo = res;
                });
            }
        },
        created () {
            this.zongjie_query_agg();
            this.schedule_query();
        },
    }
</script>

<style lang="less" scoped>
    .zongjie{
        &-top{
            padding: 0 24px;
            .p1{
                // padding-top: 20px;
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                height: 24px;
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px;
            }
            .item{
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                padding-bottom: 24px;
                .p2{
                    height: 41px;
                    font-size: 28px;
                    font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
                    font-weight: 800;
                    color: #F9C200;
                    line-height: 41px;
                    span{
                        font-size: 14px;
                        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
                .p3{

                }
            }
        }
        &-con{
            text-align: left;
            min-height: 120px;
            background: #FFFFFF;
            border-radius: 12px 12px 0px 0px;
            .title{
                height: 24px;
                font-size: 16px;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: bold;
                color: #2A3155;
                line-height: 24px;
                padding-left: 16px;
                padding-top: 12px;
                padding-bottom: 12px;
                position: relative;
                border-bottom: 1px solid rgba(42, 49, 85, 0.1);
                &::after{
                    position: absolute;
                    bottom: 0;
                    left: 16px;
                    content: '';
                    width: 32px;
                    height: 4px;
                    background: #F9C200;
                    border-radius: 2px;
                }
            }
        }
        &-info{
            box-shadow: 0px 1px 8px 0px rgba(42, 49, 85, 0.1);
            .list{
                width: 343px;
                min-height: 187px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px 0px rgba(42, 49, 85, 0.1);
                border-radius: 4px;
                border: 1px solid #E6E8EF;
                position: relative;
                .top{
                    position: absolute;
                    top: -5px;
                    left: -8px;
                    height: 26px;
                    line-height: 26px;
                    background: #9498AA;
                    color: #fff;
                    padding: 0px 12px;
                    border-radius: 2px 0px 0px 2px;
                }
                .detail{
                    padding-top: 23px;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 16px;
                    .item{
                        .tishizongjie{
                            li{
                                padding: 12px 12px;
                                border-radius: 10px;
                                background: rgba(65, 95, 213, 0.1);
                                border: 1px solid rgba(65, 95, 213, 0.3);
                                margin-top: 10px;
                                font-size: 14px;
                                .p5{
                                    font-weight: bold;
                                    color: #2A3155;
                                }
                                .p6{
                                    color: #606580;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
</style>