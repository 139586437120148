import { render, staticRenderFns } from "./zongjie.vue?vue&type=template&id=499f4fe2&scoped=true&"
import script from "./zongjie.vue?vue&type=script&lang=js&"
export * from "./zongjie.vue?vue&type=script&lang=js&"
import style0 from "./zongjie.vue?vue&type=style&index=0&id=499f4fe2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499f4fe2",
  null
  
)

export default component.exports